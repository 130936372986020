<template>
  <v-app id="app">
    <v-main>
      <WIP/>
<!--      <Main/>-->
<!--      <Schools/>-->
<!--      <Work/>-->
<!--      <Footer/>-->
<!--      <Projects/>-->
    </v-main>
  </v-app>
</template>

<script>
import WIP from "@/components/WIP";
export default {
  name: 'App',
  components: {
    WIP
  },
  data: () => ({}),
};
</script>
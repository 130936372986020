<template>
  <v-row no-gutters>
    <v-col class="text-center">
      <v-img src="../assets/tht.jpg" height="100vh"
             gradient="to top right, rgba(130,142,209,.50), rgba(130,142,209,.50)">
        <h1 >Lightventure</h1>
        <p>info@lightventure.nl</p>
      </v-img>
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: "WIP"
}
</script>

<style scoped>
h1 {
  margin-top: 50vh;
}
</style>